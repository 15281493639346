<template>
  <div style="width: 100%; height: 100%">
    <div class="a-map" ref="datamap"></div>
  </div>
</template>

<script>
  import echarts from 'echarts'

  require('echarts/map/js/china.js')
  // console.log(echarts.getMap('china').geoJson)
  const southChina = [{
    name: '湖南',
    value: [113.0823, 28.2568]
  }, {
    name: '湖北',
    value: [114.3896, 30.6628]
  }, {
    name: '安徽',
    value: [117.29, 32.0581],
  }, {
    name: '江西',
    value: [116.0046, 28.6633],
  }, {
    name: '福建',
    value: [119.4543, 25.9222],
  }, {
    name: '广西',
    value: [108.479, 23.1152],
  }, {
    name: '广东',
    value: [113.12244, 23.009505]
  }, {
    name: '贵州',
    value: [106.6992, 26.7682]
  }, {
    name: '重庆',
    value: [108.384366, 30.439702]
  }, {
    name: '四川',
    value: [103.9526, 30.7617]
  }, {
    name: '云南',
    value: [102.9199, 25.4663]
  }]

  const option = {
    tooltip: {
      show: false,
      trigger: "item",
      formatter: function(params) {
        // console.log(params)
        return '123';
      }
    },
    /*
    * 两个map叠加在一块就可以实现轮廓边框和阴影
    *  底层map 轮廓样式和阴影
     */
    geo: {
      show: true,
      map: 'china',
      zoom: 1.2,
      aspectScale: 0.75, //长宽比
      label: {
        normal: {
          show: false
        },
        emphasis: {
          show: false,
        }
      },
      roam: false,
      itemStyle: {
        areaColor: '#0C2339',
        borderColor: 'rgba(0,149,194,1)',
        borderWidth: 5,
        shadowColor: 'rgba(0, 149, 194, .5)', // 阴影颜色
        shadowBlur: 10, // 图形阴影的模糊大小
        shadowOffsetX: -2, //阴影水平方向上的偏移距离。
        shadowOffsetY: 2, //阴影垂直方向上的偏移距离。
      },
    },
    series: [
      /*
       *  显示层map 线条样式和地图样式
      */
      {
        type: 'map',
        map: 'china',
        zoom: 1.2,
        geoIndex: 1,
        aspectScale: 0.75, //长宽比
        label: {
          normal: {
            show: false
          },
          emphasis: {
            show: false,
          }
        },
        roam: false,
        itemStyle: {
          normal: {
            areaColor: '#0C2339',
            borderWidth: 1,
            borderColor: 'rgba(10, 57, 85, 1)',
          },
          emphasis: {
            areaColor: '#213c4B',
          }
        },
        animation: false,
        silent: true,
        data: []
      },
      // 涟漪气泡图
      {
        type: 'effectScatter',
        coordinateSystem: 'geo',
        label: {
          normal: {
            show: false,
          },
        },
        zlevel: 2,
        symbol: 'circle',
        symbolSize: e => {
          return e[0] === 113.0823 ? 13 : 8
        },
        itemStyle: {
          normal: {
            color: '#ECD31B',
          }
        },
        rippleEffect: {
          period: 6,
        },
        data: southChina
      },
      // 静态线
      {
        type: 'lines',
        zlevel: 3,
        effect: {
          // 线的动效
          show: false
        },
        lineStyle: {
          normal: {
            color: '#ECD31B',
            width: 1.5,
            opacity: 1,
            curveness: .2
          }
        },
        data: southChina.map((e) => {
          return {
            coords: [southChina[0].value, e.value]
          }
        })
      },
      // 运动线  配合后台接口数据
      {
        name: 'changsha',
        type: 'lines',
        zlevel: 3,
        effect: {
          // 线的动效
          color: '#ECD31B',
          show: true,
          //箭头指向速度，值越小速度越快
          period: 3,
          //特效尾迹长度[0,1]值越大，尾迹越长重
          trailLength: 0.02,
          //箭头图标
          symbol: 'arrow',
          //图标大小
          symbolSize: 8,
        },
        lineStyle: {
          normal: {
            width: 0,
            //尾迹线条曲直度
            curveness: .2
          }
        },
        data: [{
          coords: [southChina[0].value, southChina[4].value]
        }]
      },
    ]
  }
  export default {
    data () {
      return {}
    },
    methods: {
      initMap () {
        let dataMap = echarts.init(this.$refs.datamap)
        dataMap.setOption(option)
        // console.log(echarts.getMap('china').geoJson)
        // window.addEventListener('resize',function () {
        //   dataMap.resize()
        // })
      },
    },
    mounted () {
      this.initMap()
    }
  }
</script>

<style lang='sass' scoped>
  .a-map
    width: 100%
    height: 100%
</style>
